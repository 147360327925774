jQuery.noConflict();
(function ($) {

    "use strict";

    $(function () {
        window.status = [];
        window.quadrants = [];
        window.dataStatus = {};
        window.mapQuadrantStatus;
        setCalendarQuadrantAll();


        /**
         * SWITH MAP OR CALENDAR
         */
        $('.slider').on('click', function () {
            $('#calendar').toggleClass('d-none');
            $('#map').toggleClass('d-none');
            $('.list-quadrant li').removeClass('d-none');
        });

        function hasFilter() {
            var activeStep = $('.progreso-step.activeStep');
            if (activeStep.length > 0)
                return activeStep;
            return false;
        }

        $.ajaxSetup({
            async: false
        });

        $('#FichaCuadrante').on('click', '.quadrant', function (e) {
            e.preventDefault();
            $.request('Ficha::onSelect', {
                data: {id: $(this).data('numero')}
            });
            $.getJSON('/quadrant/' + $(this).data('numero'), function (data) {
                setCalendarQuadrant(data);
            });
        });

        $('.content-map-calendar').on('click', '.pillars-status-list', function (e) {
            e.preventDefault();
            $.request('Ficha::onDateOnly', {
                data: {
                    mes: $(this).data('month'),
                    dia: $(this).data('day'),
                    estatus: $(this).data('estatus'),
                }
            });
        });

        $('.content-map-calendar').on('click', '.quadrant-calendar-status', function (e) {
            e.preventDefault();
            $.request('Ficha::onCuadrante', {
                data: {
                    id: $(this).data('id'),
                }
            });
        });

        /**
         * JSON ALL PILLARS
         */
        $.getJSON('/data', function (data) {
            window.quadrants = data;
        });

        /**
         * ACTION CLICK TIME LINE STATUS QUADRANT
         */
        $('.progreso-step').click(function (e) {
            var estatus = $(this).data('estatus');
            var stepActive = hasFilter();
            var actualStep = '';
            if (stepActive) {
                actualStep = stepActive.data('estatus');
            }
            if (estatus !== actualStep) {
                $('.progreso-step').addClass('hideStep').removeClass('activeStep');
                $(this).addClass('activeStep').removeClass('hideStep');
                setQuadrantsMapByStatus(estatus);
                setQuadrantsListByStatus(estatus);
                setCalendarQuadrantStatusSelect(estatus);
            } else {
                $('.progreso-step').removeClass('hideStep').removeClass('activeStep');
                setCalendarQuadrantAll();
                setMapAllQuadrants();
                $.request('Ficha::onInit');
            }
        });

        function setQuadrantsListByStatus(estatus) {
            $.request('Ficha::onEstatus', {
                data: {estatus: estatus}
            });
        }

        /**
         * SEARCH AUTOCOMPLETE Quadrant
         */
        $('#select-search').autocomplete({
            source: function (request, response) {
                var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");

                var matching = $.grep(window.quadrants, function (value) {
                    //var name = value.quadrant + ' ' + value.colony + ' ' + value.address + ' ' + value.town_hall + ' ' + value.zone;
                    var name = value.quadrant + ' ' + value.colony + ' ' + value.town_hall + ' ' + value.zone;
                    var id = value.id;

                    return matcher.test(name) || matcher.test(id);
                });

                response($.map(matching, function (value, key) {
                    return {
                        label: value.colony + ' - ' + value.quadrant,
                        value: value.quadrant
                    }
                }));
            },
            select: function (event, ui) {
                $.request('Ficha::onSelect', {
                    data: {id: ui.item.value}
                });
                $.getJSON('/quadrant/' + ui.item.value, function (data) {
                    setCalendarQuadrant(data);
                });
                $("#select-search").val(ui.item.label);
                return false;
            },
            focus: function (event, ui) {
                $("#select-search").val(ui.item.label);
                return false;
            }
        });


        /**
         * UPDATE QUADRANT IN CALENDAR BY STATUS DATE
         */
        function setCalendarQuadrantAll() {
            $('.month-calendar .days').removeClass('d-none');
            $('.month-calendar .status-month').addClass('d-none');
            $('.month-calendar .days li span').remove();
            $('.month-calendar .days li').attr('data-count', 0);
            $.getJSON('/fechas', function (data) {
                $.each(data, function (date, quadrants) {
                    var month = formatDate(date, 'month');
                    var day = formatDate(date, 'day');
                    var dayNew = day.replace(/^0+/, '');
                    var point_html = '<span class="status quadrant-calendar-status default quadrantdate" data-date="' + date + '">&nbsp;</span>';
                    $('#month-' + month + ' .days-month .day-' + dayNew).append(point_html);
                });
            });
        }

        $('.content-map-calendar').on('click', '.quadrantdate', function (e) {
            e.preventDefault();
            $.request('Ficha::onDateGeneral', {
                data: {fecha: $(this).data('date')}
            });
        });

        $('#FichaCuadrante').on('click', '.close-info-quadrant', function (e) {
            e.preventDefault();
            $.request('Ficha::onInit');
            setCalendarQuadrantAll();
        });

        /**
         * ADD DATES STATUS LIST IN MONTHS OF QUADRANTS SEARCH
         */
        function setCalendarQuadrant(data) {
            $('.month-calendar .days').removeClass('d-none');
            $('.month-calendar .days li span').remove();

            $('.month-calendar .status-month').addClass('d-none');
            $('#calendar').removeClass('calendario-status');
            console.log(data[0].date_constitutive_assembly_new);
            var status = 0;
            if (data[0].date_constitutive_assembly_new != '' && data[0].date_constitutive_assembly_new != null && typeof data[0].date_constitutive_assembly_new != 'undefined') {
                var month = formatDate(data[0].date_constitutive_assembly_new, 'month');
                var year = formatDate(data[0].date_constitutive_assembly_new, 'year');
                var day = formatDate(data[0].date_constitutive_assembly_new, 'day');
                var dayNew = day.replace(/^0+/, '');
                var html = htmlCalendarQuadrant(data[0].date_constitutive_assembly_new, data[0].colony + ' - ' + data[0].quadrant, '<span class="status asamblea-constitutiva"></span>', 'Asamblea constitutiva', data[0].id);

                if (year == '2019') {
                    $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + data[0].id + '" class="status quadrant-calendar-status asamblea-constitutiva">' + html + '</span>');
                    $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);
                }
                status = 1;
            }

            if (data[0].date_pact != '' && data[0].date_pact != null && typeof data[0].date_pact != 'undefined' && status == 0) {
                var month = formatDate(data[0].date_pact, 'month');
                var year = formatDate(data[0].date_pact, 'year');
                var day = formatDate(data[0].date_pact, 'day');
                var dayNew = day.replace(/^0+/, '');
                var html = htmlCalendarQuadrant(data[0].date_pact, data[0].colony + ' - ' + data[0].quadrant, '<span class="status asamblea-constitutiva"></span>', 'Asamblea constitutiva', data[0].id);

                if (year == '2019') {
                    $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + data[0].id + '" class="status quadrant-calendar-status asamblea-constitutiva">' + html + '</span>');
                    $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);
                }

                status = 1;
            }

            if (data[0].date_constitutive_assembly != '' && data[0].date_constitutive_assembly != null && typeof data[0].date_constitutive_assembly != 'undefined' && status == 0) {
                var month = formatDate(data[0].date_constitutive_assembly, 'month');
                var year = formatDate(data[0].date_constitutive_assembly, 'year');
                var day = formatDate(data[0].date_constitutive_assembly, 'day');
                var dayNew = day.replace(/^0+/, '');
                var html = htmlCalendarQuadrant(data[0].date_constitutive_assembly, data[0].colony + ' - ' + data[0].quadrant, '<span class="status asamblea-constitutiva"></span>', 'Asamblea constitutiva', data[0].id);

                if (year == '2019') {
                    $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + data[0].id + '" class="status quadrant-calendar-status asamblea-constitutiva">' + html + '</span>');
                    $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);
                }

                status = 1;
            }

            if (data[0].date_informative_assembly != '' && data[0].date_informative_assembly != null && typeof data[0].date_informative_assembly != 'undefined' && status == 0) {
                var month = formatDate(data[0].date_informative_assembly, 'month');
                var year = formatDate(data[0].date_informative_assembly, 'year');
                var day = formatDate(data[0].date_informative_assembly, 'day');
                var dayNew = day.replace(/^0+/, '');
                var html = htmlCalendarQuadrant(data[0].date_informative_assembly, data[0].colony + ' - ' + data[0].quadrant, '<span class="status asamblea-constitutiva"></span>', 'Asamblea constitutiva', data[0].id);

                if (year == '2019') {
                    $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + data[0].id + '" class="status quadrant-calendar-status asamblea-constitutiva">' + html + '</span>');
                    $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);
                }

                status = 1;
            }

            if (data[0].date_evaluation_and_tracing != '' && data[0].date_evaluation_and_tracing != null && typeof data[0].date_evaluation_and_tracing != 'undefined') {
                var month = formatDate(data[0].date_evaluation_and_tracing, 'month');
                var year = formatDate(data[0].date_evaluation_and_tracing, 'year');
                var day = formatDate(data[0].date_evaluation_and_tracing, 'day');
                var dayNew = day.replace(/^0+/, '');
                var html = htmlCalendarQuadrant(data[0].date_evaluation_and_tracing, data[0].colony + ' - ' + data[0].quadrant, '<span class="status evaluacion-y-seguimiento"></span>', 'Evaluación y seguimiento', data[0].id);

                if (year == '2019') {
                    $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + data[0].id + '" class="status quadrant-calendar-status evaluacion-y-seguimiento">' + html + '</span>');
                    $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);
                }

            }

            $('#calendar').removeClass('d-none');
            $('#map').addClass('d-none');

            if (!$('input.active-calendar').is(':checked')) {
                $("input.active-calendar").prop("checked", true);
            }

        }

        /**
         * UPDATE QUADRANT IN CALENDAR BY STATUS DATE
         */
        function setCalendarQuadrantStatusSelect(estatus) {
            $('.month-calendar .days').removeClass('d-none');
            $('.month-calendar .status-month').addClass('d-none');
            $('.month-calendar .days li span').remove();
            $('.month-calendar .days li').attr('data-count', 0);
            $.getJSON('/quadrant/estatus/' + estatus, function (data) {
                var date = getDateField(estatus);
                var namestatus = textStatus(estatus);
                
                $.each(data, function (i, quadrant) {
                    var object = {};
                    var quadrantDate = '';
                    var success = 0;

                    if( quadrant.date_evaluation_and_tracing != '' && quadrant.date_evaluation_and_tracing !== null && estatus == 'evaluacion-y-seguimiento' ){
                        quadrantDate = quadrant.date_evaluation_and_tracing;
                        success = 1;
                    }

                    if(quadrant.date_constitutive_assembly_new != '' && quadrant.date_constitutive_assembly_new !== null && estatus == 'asamblea-constitutiva' && success == 0){
                        quadrantDate = quadrant.date_constitutive_assembly_new;
                        success = 1;
                    }

                    if(quadrant.date_pact != '' && quadrant.date_pact !== null && estatus == 'asamblea-constitutiva' && success == 0){
                        quadrantDate = quadrant.date_pact;
                        success = 1;
                    }

                    if(quadrant.date_constitutive_assembly != '' && quadrant.date_constitutive_assembly !== null && estatus == 'asamblea-constitutiva' && success == 0){
                        quadrantDate = quadrant.date_constitutive_assembly;
                        success = 1;
                    }

                    if(quadrant.date_informative_assembly != '' && quadrant.date_informative_assembly !== null && estatus == 'asamblea-constitutiva' && success == 0){
                        quadrantDate = quadrant.date_informative_assembly;
                        success = 1;
                    }

                    if (quadrantDate != '' && quadrantDate !== null) {
                        var month = formatDate(quadrantDate, 'month');
                        var day = formatDate(quadrantDate, 'day');
                        var dayNew = day.replace(/^0+/, '');
                        var year = formatDate(quadrantDate, 'year');

                        var count = $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count');

                        if (year == '2019' && count == 0) {
                            var html = htmlCalendarQuadrant(quadrantDate, quadrant.colony + ' - ' + quadrant.quadrant, '<span class="status ' + estatus + '"></span>', namestatus, quadrant.id);
                            $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + quadrant.id + '" class="status quadrant-calendar-status ' + estatus + '">' + html + '</span>');
                            $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);

                            var name = month;
                            if (name in window.dataStatus == false) {
                                window.dataStatus[name] = {}; // must initialize the sub-object, otherwise will get 'undefined' errors
                            }

                            window.dataStatus[name][i] = {
                                date: quadrantDate,
                                html: html
                            };
                        } else if (year == '2019' && count == 1) {
                            var html = htmlCalendarQuadrant(quadrantDate, quadrant.colony + ' - ' + quadrant.quadrant, '<span class="status ' + estatus + '"></span>', namestatus, quadrant.id);
                            $('#month-' + month + ' .days-month .day-' + dayNew + ' span').remove();
                            $('#month-' + month + ' .days-month .day-' + dayNew).append('<span data-id="' + quadrant.id + '" data-month="' + month + '" data-day="' + day + '" data-estatus="' + estatus + '" class="status pillars-status-list ' + estatus + '"></span>');
                            $('#month-' + month + ' .days-month .day-' + dayNew).attr('data-count', 1);

                            var name = month;

                            window.dataStatus[name][i] = {
                                date: quadrantDate,
                                html: html
                            };
                        }
                    }
                });
            });
        }

        window.mapQuadrant = 0;
        window.oldMarquer;


        /**
         * HTML QUADRANT LIST CALENDAR
         */
        function htmlCalendarQuadrant(fecha, nombre_inmueble, statusHtml, status, data_id) {
            var html = '<div class="date-status" data-id="' + data_id + '">';
            html += '<div class="cont-status">' + statusHtml + '</div>';
            html += '<div class="info">';
            html += '<div class="name">' + nombre_inmueble + '</div>';
            html += '<div class="date">' + status + ': ' + fecha + '</div>';
            html += '</div>';

            html += '</div>';

            return html;
        }

        /**
         * SET FORMAT DATE
         */
        function formatDate(date, response) {
            if (response == 'month')
                return date.substring(5, 7);

            if (response == 'year')
                return date.substring(0, 4);

            if (response == 'day')
                return date.substring(8, 10);

            return false;
        }


        ///// MAPAS ////////////////////////////////////////////////////////////

        function setQuadrantsMapByStatus(estatus) {
            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png', {
                maxZoom: 19
            }).addTo(window.mapQuadrantStatus);
            var client = new carto.Client({
                apiKey: 'YvLBZXq1XEGeMfM4vLlk5A',
                username: 'javiergc93'
            });
            var source = new carto.source.SQL('SELECT * FROM cuadrantes_2019');
            var color = colorStatus(estatus);

            if(estatus == 'asamblea-constitutiva'){
                color = "#3ea028, #3ea028, #3ea028, #3ea028";
                estatus = "'asamblea-constitutiva','asamblea-informativa','asamblea-compromisos','compromisos'";
            }

            var d6Style = new carto.style.CartoCSS(`
	    	  #layer {
	    	    polygon-opacity: 0.8;
	    	    polygon-fill: ramp([estatus], (${color}, #FFFFFF), (${estatus}), “=”, category);
	    	    ::outline {
	    	      line-width: .5;
	    	      line-color: #000000;
	    	      line-opacity: 0.8;
	    	    }
	    	  }
	    	`);

            var layer = new carto.layer.Layer(source, d6Style, {
                featureOverColumns: ['sector2', 'nomenclatu', 'estatus']
            });

            client.addLayer(layer);
            client.getLeafletLayer().addTo(window.mapQuadrantStatus);

        }

        function setMapAllQuadrants() {
            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png', {
                maxZoom: 19
            }).addTo(window.mapQuadrantStatus);

            var client = new carto.Client({
                apiKey: 'YvLBZXq1XEGeMfM4vLlk5A',
                username: 'javiergc93'
            });
            var source = new carto.source.SQL('SELECT * FROM cuadrantes_2019');

            var d6Style = new carto.style.CartoCSS(`
	    	  #layer {
	    	    polygon-opacity: 0.8;
	    	    polygon-fill: ramp([estatus], (#3ea028, #3ea028, #3ea028, #3ea028, #83e86c, #FFFFFF), ('asamblea-constitutiva','asamblea-informativa','asamblea-compromisos','compromisos','evaluacion-y-seguimiento'), “=”, category);
	    	    ::outline {
	    	      line-width: .5;
	    	      line-color: #000000;
	    	      line-opacity: 0.8;
	    	    }
	    	  }
	    	`);

            var layer = new carto.layer.Layer(source, d6Style, {
                featureOverColumns: ['sector2', 'nomenclatu']
            });

            client.addLayer(layer);
            client.getLeafletLayer().addTo(window.mapQuadrantStatus);

        }

        function getSelectedZona() {
            const inputControls = document.querySelectorAll('#controls input');
            const values = [];

            inputControls.forEach(input => input.checked ? values.push(input.value) : null);
            return values;
        }

        function applyFilters() {
            zonaFilter.setFilters({in: getSelectedZona()});
            // or
            // roomTypeFilter.set('in', getSelectedRoomTypes());
        }

        function registerListeners() {
            document.querySelectorAll('#controls input').forEach(
                input => input.addEventListener('click', () => applyFilters())
            );
        }
        window.mapQuadrantStatus = L.map('map-carto', {
            minZoom: 11,
            maxZoom: 16
        });
        window.mapQuadrantStatus.setView([19.430556, -99.133030], 11);

        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png', {
            maxZoom: 19
        }).addTo(window.mapQuadrantStatus);

        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png').addTo(window.mapQuadrantStatus);
        var options = {
            position: "topleft",
            geocoder: L.Control.Geocoder.nominatim({
                geocodingQueryParams: {
                    countrycodes: 'mx'
                }
            })
        };
        L.Control.geocoder(options).addTo(window.mapQuadrantStatus);

        const client = new carto.Client({
            apiKey: 'YvLBZXq1XEGeMfM4vLlk5A',
            username: 'javiergc93'
        });

        const source = new carto.source.SQL('SELECT * FROM cuadrantes_2019');

        const d6Style = new carto.style.CartoCSS(`
    	  #layer {
    	    polygon-opacity: 0.8;
    	    polygon-fill: ramp([estatus], (#3ea028, #3ea028, #3ea028, #3ea028, #83e86c, #FFFFFF), ('asamblea-constitutiva','asamblea-informativa','asamblea-compromisos','compromisos','evaluacion-y-seguimiento'), “=”, category);
    	    ::outline {
    	      line-width: .5;
    	      line-color: #000000;
    	      line-opacity: 0.8;
    	    }
    	  }
    	`);

        const layer = new carto.layer.Layer(source, d6Style, {
            featureOverColumns: ['sector2', 'nomenclatu', 'estatus']
        });

        client.addLayer(layer);
        client.getLeafletLayer().addTo(window.mapQuadrantStatus);


        const popup = L.popup({closeButton: true});

        function showFicha(featureEvent) {
            $.request('Ficha::onSelect', {
                data: {id: featureEvent.data.nomenclatu}
            });
            $.getJSON('/quadrant/' + featureEvent.data.nomenclatu, function (data) {
                $.each(data, function( i, d ) {
                    setCalendarQuadrant(d);
                });
            });
            if(($('#mapModal')).length > 0){
                $('#mapModal').modal('show');
            }
        }

        function overPopup(featureEvent) {
            let content = '<div class="widget">';

            if (featureEvent.data.sector2) {
                var estatus = textStatus(featureEvent.data.estatus);
                content += `<p><b>Cuadrante: </b>${featureEvent.data.sector2}</p>`;
                content += `<p><b>Paso actual: </b>${estatus}</p>`;
            }

            content += `</div>`;

            popup.setContent(content);
            popup.setLatLng(featureEvent.latLng);
            if (!popup.isOpen()) {
                popup.openOn(window.mapQuadrantStatus);
            }
        }

        function closePopup(featureEvent) {
            popup.removeFrom(window.mapQuadrantStatus);
        }

        layer.on('featureOver', overPopup);
        layer.off('featureOut');
        layer.on('featureClicked', showFicha);

        registerListeners();

        ///// END MAPAS ////////////////////////////////////////////////////////////


        function colorStatus(status) {
            switch (status) {
                case 'asamblea-constitutiva':
                    return '#3ea028';
                case 'asamblea-informativa':
                    return '#3ea028';
                case 'asamblea-compromisos':
                    return '#3ea028';
                case 'compromisos':
                    return '#3ea028';
                case 'capacitacion':
                    return '#6ce8b8';
                case 'evaluacion-y-seguimiento':
                    return '#83e86c';
                default:
                    return '#FFFFFF';
            }
        }

        function getDateField(estatus) {
            switch (estatus) {
                case 'asamblea-constitutiva':
                    return 'date_constitutive_assembly_new';
                case 'asamblea-informativa':
                    return 'date_constitutive_assembly_new';
                case 'asamblea-compromisos':
                    return 'date_constitutive_assembly_new';
                case 'compromisos':
                    return 'date_constitutive_assembly_new';
                case 'capacitacion':
                    return 'date_training';
                case 'evaluacion-y-seguimiento':
                    return 'date_evaluation_and_tracing';
            }
        }

        function textStatus(status) {
            switch (status) {
                case 'asamblea-constitutiva':
                    return 'Asamblea constitutiva';
                case 'asamblea-informativa':
                    return 'Asamblea constitutiva';
                case 'asamblea-compromisos':
                    return 'Asamblea constitutiva';
                case 'compromisos':
                    return 'Asamblea constitutiva';
                case 'capacitacion':
                    return 'Capacitación Protección Civil';
                case 'evaluacion-y-seguimiento':
                    return 'Evaluación y seguimiento';
                default:
                    return 'S/D';
            }
        }
    });
})(jQuery);
